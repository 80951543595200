import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

@Component({
  selector: 'app-leshack-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './menu.component.html',
})
export class MenuComponent {
  public items: NbMenuItem[] = [
    {
      title: 'Dashboard',
      icon: 'calendar-outline',
      link: '/calendar',
    },
    {
      title: 'Extra Services',
      icon: 'shopping-bag-outline',
      link: '/extra-services',
    },
    {
      title: 'Coaches',
      icon: 'compass-outline',
      link: '/coaches',
    },
    {
      title: 'Conferences',
      icon: 'mic-outline',
      link: '/conferences',
    },
    {
      title: 'Wellness Lessons',
      icon: 'trending-up-outline',
      link: '/wellness-lessons',
    },
    {
      title: 'Meetings',
      icon: 'people-outline',
      link: '/meeting-rooms',
    },
    {
      title: 'Seminaries',
      icon: 'message-square-outline',
      link: '/seminary-rooms',
    },
    {
      title: 'Requests',
      icon: 'phone-outline',
      link: '/requests',
    },
    {
      title: 'Orders',
      icon: 'printer-outline',
      link: '/orders',
    },
    {
      title: 'Invoices',
      icon: 'clipboard-outline',
      link: '/invoices',
    },
    {
      title: 'Rooms',
      icon: 'grid-outline',
      link: '/rooms',
    },
    {
      title: 'Promo Codes',
      icon: 'percent-outline',
      link: '/promo-codes',
    },
    {
      title: 'Users',
      icon: 'person-outline',
      link: '/users',
    },
    {
      title: 'Logout',
      icon: 'undo-outline',
      link: '/auth/logout',
    },
  ];
}
