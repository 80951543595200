import { FormArray, FormControl, FormGroup } from '@angular/forms';

export function validateForm(formGroup: FormGroup | FormArray): void {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);

    if (control instanceof FormControl) {
      control.markAsTouched();

      control.updateValueAndValidity({ onlySelf: true });
    } else if (control instanceof FormArray) {
      validateForm(control);
    } else if (control instanceof FormGroup) {
      validateForm(control);
    }
  });
}
