<div class="form-group">
  <p class="label">{{ this.name }}:</p>
  <button disabled="image.key" nbButton class="leshack-upload-label" (click)="imageInput.click()">Upload</button>
  <br />
  <div class="leshack-file-upload-text">
    <input #imageInput type="file" class="leshack-file-input" nbInput fullWidth (change)="onImageSelected($event)" />
    <div class="leshack-image-field" *ngIf="this.image.key">
      {{ this.image.key }}
      <nb-icon class="leshack-pointer" icon="close-outline" (click)="removeImage()"></nb-icon>
    </div>
  </div>
  <br *ngIf="this.image.key" />
  <div class="leshack-file-upload-preview" *ngIf="this.image.key">
    <img [src]="this.image.location" alt="Preview" />
  </div>
  <br *ngIf="this.control.invalid && this.control.touched" />
  <div *ngIf="this.control.invalid && this.control.touched" class="alert alert-danger">
    <div *ngIf="this.control.errors.required">
      <nb-icon icon="alert-circle-outline"></nb-icon>
      The {{ this.name }} is a required field.
    </div>
  </div>
</div>
