import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export interface SelectFormControlOption<T = string> {
  value: T;
  displayText: string;
}

@Component({
  selector: 'app-select-form-control',
  templateUrl: './select-form-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFormControlComponent),
      multi: true,
    },
  ],
})
export class SelectFormControlComponent implements OnInit, ControlValueAccessor {
  @Input() public formControlName: string;

  @Input() public label: string;
  @Input() public isRequired = false;
  @Input() public options: SelectFormControlOption[];

  public control: AbstractControl;
  public value: string;
  public onChange = (_: any): void => {};
  public onTouch = (): void => {};

  public constructor(private controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName);

        this.control.setValidators(this.defineValidators());
      } else {
        console.warn('Missing formControlName from form group component');
      }
    } else {
      console.warn('Missing form group container');
    }
  }

  private defineValidators(): ValidatorFn[] {
    const validators = [];

    if (this.isRequired) {
      validators.push(Validators.required);
    }

    return validators;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }
}
