import gql from 'graphql-tag';

export const getTableCoaches = gql`
  query getTableCoaches($limit: Int, $offset: Int) {
    getTableCoaches(limit: $limit, offset: $offset) {
      rows {
        coachId
        name
        description
      }
      count
    }
  }
`;

export const getCoaches = gql`
  query getCoaches {
    getCoaches {
      coachId
      name
      description
    }
  }
`;

export const productTypeQuery = gql`
  query getProductTypes {
    getProductTypes {
      productTypeId
      title
    }
  }
`;

export const roomQuery = gql`
  query getRooms($filter: RoomFilters!) {
    getRooms(filter: $filter) {
      roomId
      title
    }
  }
`;

export const removeSchedule = gql`
  mutation removeSchedule($productScheduleId: ID!) {
    removeSchedule(productScheduleId: $productScheduleId) {
      productScheduleId
    }
  }
`;

export const deleteProduct = gql`
  mutation deleteProduct($productId: ID!) {
    deleteProduct(productId: $productId) {
      productId
    }
  }
`;

export const restoreProduct = gql`
  mutation restoreProduct($productId: ID!) {
    restoreProduct(productId: $productId) {
      productId
    }
  }
`;

export const getExtraServicesQuery = gql`
  query getExtraServices($filter: ExtraServiceFilter!) {
    getExtraServices(filter: $filter) {
      extraServiceId
      description
      settings {
        title
        memberPrice
        publicPrice
      }
      type
    }
  }
`;

export const getPromoCodesQuery = gql`
  query getPromoCodes($filter: PromoCodesFilter!) {
    getPromoCodes(filter: $filter) {
      promoCodeId
      value
      discount
      startDate
      endDate
      valid
    }
  }
`;

export const getImageUploadDataQuery = gql`
  query getImageUploadData {
    getImageUploadData {
      accessKey
      secretKey
      bucketName
    }
  }
`;

export const deleteImage = gql`
  mutation removeUpdatedProductImage($imageId: ID!) {
    removeUpdatedProductImage(imageId: $imageId) {
      key
    }
  }
`;

export const createProduct = gql`
  mutation createProduct($input: ProductInput!) {
    createProduct(input: $input) {
      productId
    }
  }
`;

export const updateProduct = gql`
  mutation updateProduct($productId: ID!, $input: ProductInput) {
    updateProduct(productId: $productId, input: $input) {
      productId
    }
  }
`;
