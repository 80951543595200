import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ViewStateService {
  public isLoading = true;

  public setLoading(loading: boolean): void {
    this.isLoading = loading;
  }
}
