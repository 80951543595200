import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Apollo, ApolloModule } from 'apollo-angular';
import { DefaultOptions } from 'apollo-client';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { GraphqlApolloLinkBuilder } from './graphql-apollo-link-builder';

@NgModule({
  providers: [GraphqlApolloLinkBuilder],
  exports: [HttpClientModule, ApolloModule, HttpLinkModule],
})
export class GraphQLModule {
  public constructor(apollo: Apollo, authenticator: GraphqlApolloLinkBuilder) {
    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };

    apollo.create({
      link: authenticator.withAuthApolloLink(),
      cache: new InMemoryCache(),
      defaultOptions,
    });
  }
}
