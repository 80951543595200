import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_TABLE_OFFSET, DEFAULT_TABLE_LIMIT } from '../../variables';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  public limit: number;
  public offset: number;

  @Input()
  public count: number;

  public constructor(private router: Router, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.offset = DEFAULT_TABLE_OFFSET;
    this.limit = DEFAULT_TABLE_LIMIT;
    this.route.queryParams.subscribe(({ offset }) => {
      this.offset = Number(offset) || DEFAULT_TABLE_OFFSET;
    });
  }

  public setQueryParams = (offset): Promise<boolean> => {
    return this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        offset: Number(offset),
      },
      queryParamsHandling: 'merge',
    });
  };

  public nextPage = (): Promise<boolean> => {
    return this.setQueryParams(this.offset + this.limit);
  };

  public prevPage = (): Promise<boolean> => {
    let offsetValue;
    if (this.offset >= this.limit) {
      offsetValue = this.offset - this.limit;
    } else {
      offsetValue = 0;
    }
    return this.setQueryParams(offsetValue);
  };
}
