<nb-card>
  <nb-card-header>Name the list</nb-card-header>
  <nb-card-body>
    <p>Give the name to hubspot list</p>
    <input nbInput [(ngModel)]="name" />
  </nb-card-body>
  <nb-card-footer class="leshack-name-dialog-footer">
    <button nbButton status="info" (click)="onCancel()">Cancel</button>
    <button nbButton status="success" (click)="onApprove()">Approve</button>
  </nb-card-footer>
</nb-card>
