import gql from 'graphql-tag';

export const deleteCoachImageMutation = gql`
  mutation deleteCoachImage($coachImageId: ID!) {
    deleteCoachImage(coachImageId: $coachImageId) {
      coachImageId
      key
      location
    }
  }
`;
