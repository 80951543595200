import { NgModule } from '@angular/core';
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbGlobalLogicalPosition,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbToastrModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { library as fontLibrary } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons';

fontLibrary.add(faCalendar, faClock);

@NgModule({
  imports: [
    NgbModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    CommonModule,
    TextMaskModule,
    FormsModule,
    NbEvaIconsModule,
    NbCheckboxModule,
    NbSpinnerModule,
    NbSelectModule,
    NbAlertModule,
    NbIconModule,
    NbTreeGridModule,
    NbUserModule,
    NbLayoutModule,
    NbButtonModule,
    NbInputModule,
    NbCardModule,
    NbListModule,
    NbSidebarModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot({ closeOnBackdropClick: false }),
    NbToastrModule.forRoot({
      position: NbGlobalLogicalPosition.BOTTOM_END,
      duration: 10000,
      limit: 5,
      icon: 'alert-triangle',
    }),
  ],
  exports: [
    NgbModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    CommonModule,
    TextMaskModule,
    FormsModule,
    NbEvaIconsModule,
    NbSidebarModule,
    NbCheckboxModule,
    NbSpinnerModule,
    NbSelectModule,
    NbAlertModule,
    NbDialogModule,
    NbIconModule,
    NbTreeGridModule,
    NbUserModule,
    NbLayoutModule,
    NbButtonModule,
    NbInputModule,
    NbCardModule,
    NbListModule,
    NbDatepickerModule,
    NbMenuModule,
    NbToastrModule,
  ],
})
export class ProviderModule {}
