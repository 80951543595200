import { AfterViewInit, Component, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { noop } from 'rxjs';
import { NgbDateStruct, NgbPopover, NgbPopoverConfig, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { DateTimeModel } from './model';
import { TIME_FORMATS } from '../../variables';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
  ],
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input()
  public mode = 'datetime';
  @Input()
  public dateString: string;
  @Input()
  public inputDatetimeFormat = 'MM/dd/yyyy HH:mm';
  @Input()
  public hourStep = 1;
  @Input()
  public minuteStep = 15;
  @Input()
  public secondStep = 30;
  @Input()
  public seconds = true;
  @Input()
  public disabled = false;

  public dateTime: DateTimeModel = new DateTimeModel();
  public showTimePickerToggle = false;
  public ngControl: NgControl;

  private firstTimeAssign = true;
  private firstSchedule = true;

  @ViewChild(NgbPopover)
  private popover: NgbPopover;

  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  public constructor(private config: NgbPopoverConfig, private inj: Injector) {
    config.autoClose = 'outside';
    config.placement = 'auto';
  }

  public ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
  }

  public ngAfterViewInit(): void {
    this.popover.hidden.subscribe(() => {
      this.showTimePickerToggle = false;
    });
  }

  public writeValue(newModel: string): void {
    if (newModel) {
      this.dateTime = this.dateTime.fromLocalString(newModel, TIME_FORMATS.DATE);
      this.dateString = newModel;
      this.setDateStringModel();
    } else {
      this.dateTime = new DateTimeModel();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public toggleDateTimeState($event): void {
    if (this.mode === 'datetime') {
      this.showTimePickerToggle = !this.showTimePickerToggle;
    }

    $event.stopPropagation();
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onInputChange($event: any): void {
    const value = $event.target.value;
    const dt = this.dateTime.fromLocalString(value);
    if (dt) {
      this.dateTime = dt;
      this.setDateStringModel();
    } else if (value.trim() === '') {
      this.dateTime = new DateTimeModel();
      this.dateString = '';
      this.onChange(this.dateString);
    } else {
      this.onChange(value);
    }
  }

  public onDateChange($event: string | NgbDateStruct): void {
    const event = $event as NgbDateStruct;

    if (event != null) {
      if (event.year) {
        $event = `${event.year}-${event.month}-${event.day}`;
      }
    }

    let date: DateTimeModel = null;

    const stringEvent = $event as string;

    if (stringEvent !== null) {
      try {
        if (!stringEvent || !this.firstSchedule) throw new Error();
        date = this.dateTime.fromLocalString(stringEvent);
        this.firstSchedule = false;
      } catch {
        const [year, month, day] = $event.toString().split('-');
        date = new DateTimeModel({ year: Number(year), month: Number(month), day: Number(day) });
      }
    }

    if (!date) {
      this.dateString = this.dateString;
      return;
    }

    if (!this.dateTime) {
      this.dateTime = date;
    }

    this.dateTime.year = date.year;
    this.dateTime.month = date.month;
    this.dateTime.day = date.day;
    this.dateTime.timeZoneOffset = new Date(this.dateTime.toString()).getTimezoneOffset();

    this.setDateStringModel();
  }

  public onTimeChange(event: NgbTimeStruct): void {
    this.dateTime.hour = event?.hour;
    this.dateTime.minute = event?.minute;
    this.dateTime.second = event?.second;

    this.setDateStringModel();
  }

  public setDateStringModel(): void {
    if (this.dateTime) {
      this.dateString = this.dateTime.toString();
    }

    if (!this.firstTimeAssign) {
      this.onChange(this.dateString);
      this.onTouched();
    } else {
      // Skip very first assignment to null done by Angular
      if (this.dateString !== null) {
        this.firstTimeAssign = false;
        this.onChange(this.dateString);
        this.onTouched();
      }
    }
  }

  public inputBlur(): void {
    this.onTouched();
  }
}
