import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { ImageFormData } from '../../../interfaces';
import { NbToastrService } from '@nebular/theme';
import { Guid } from 'guid-typescript';
import unorm from 'unorm';
import { FileUploadService, ImageUploadService, ViewStateService } from '../../../services';
import { DocumentNode } from 'graphql';

@Component({
  selector: 'app-image-upload-form-control',
  templateUrl: './image-upload-form-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadFormControlComponent),
      multi: true,
    },
  ],
})
export class ImageUploadFormControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public formControlName: string;
  @Input() public label: string;
  @Input() public removeMutation: DocumentNode;

  public form: FormGroup;
  public control: FormArray;

  public imageFormatPattern = /\.(jpe?g|png)$/;

  public constructor(
    private apollo: Apollo,
    private fileUploadService: FileUploadService,
    private toastrService: NbToastrService,
    private formBuilder: FormBuilder,
    private viewStateService: ViewStateService,
    private imageUploadService: ImageUploadService,
  ) {}

  public ngOnInit(): void {
    if (!this.removeMutation) {
      console.warn('Mutation was not provided to image upload component.');
    }

    this.viewStateService.setLoading(true);

    this.defineForm();

    this.imageUploadService.clearPendingKeys();

    this.viewStateService.setLoading(false);
  }

  public ngOnDestroy(): void {
    [...this.imageUploadService.pendingImageKeys].forEach((key) => {
      this.deleteImageFromBucket(key);
    });
  }

  private defineForm(): void {
    this.control = this.formBuilder.array([]);

    this.form = this.formBuilder.group({
      [this.formControlName]: this.control,
    });
  }

  public onFileSelected($event: Event): void {
    this.viewStateService.setLoading(true);

    const element = $event.target as HTMLInputElement;

    const file = element.files[0];

    if (!file) {
      this.toastrService.warning('File is undefined', 'Warning', { icon: 'alert-triangle-outline' });
      return;
    }
    if (this.imageFormatPattern.test(file.type)) {
      this.toastrService.warning('File is undefined', 'Warning', { icon: 'alert-triangle-outline' });
      return;
    }

    const key = `${Guid.create()}-${unorm.nfc(file.name)}`;

    this.fileUploadService
      .uploadFile({
        file,
        key,
      })
      .subscribe(
        (data) => {
          const { key, location } = data;

          this.addPendingImage({
            key,
            location,
          });

          this.viewStateService.setLoading(false);
        },
        (error) => {
          this.toastrService.danger(error.name, 'Error', { icon: 'close-outline' });

          this.viewStateService.setLoading(false);
        },
      );
  }

  private addPendingImage(image: ImageFormData): void {
    this.addImageControl(image);

    this.imageUploadService.addPendingKey(image.key);
  }

  private addImageControl(image: ImageFormData): void {
    const control = this.formBuilder.control({ value: image, disabled: true });

    this.control.push(control);
  }

  private removeImageControl(controlIndex: number): void {
    this.control.removeAt(controlIndex);
  }

  public removeImage(controlIndex: number): void {
    this.viewStateService.setLoading(true);

    const imageData = this.control.controls[controlIndex].value as ImageFormData;

    const isPending = this.imageUploadService.pendingImageKeys.includes(imageData.key);

    if (isPending && !imageData.imageId) {
      this.deleteImageFromBucket(imageData.key);
    } else if (this.removeMutation) {
      this.deleteImage(imageData);
    }

    this.removeImageControl(controlIndex);
  }

  private deleteImage(image: ImageFormData): void {
    this.apollo
      .mutate({
        mutation: this.removeMutation,
        variables: { imageId: image.imageId },
      })
      .subscribe(
        () => {
          this.deleteImageFromBucket(image.key);
        },
        (error) => {
          this.toastrService.danger(error, 'Error', { icon: 'close-outline' });

          this.viewStateService.setLoading(false);
        },
      );
  }

  private deleteImageFromBucket(key: string): void {
    this.fileUploadService.removeFile(key).subscribe(
      () => {
        this.imageUploadService.removePendingKey(key);

        this.viewStateService.setLoading(false);
      },
      (err) => {
        this.toastrService.danger(err, 'Error', { icon: 'close-outline' });

        this.viewStateService.setLoading(false);
      },
    );
  }

  public registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.control.statusChanges.subscribe(fn);
  }

  public writeValue(values: ImageFormData[]): void {
    if (values && values.length) {
      values.forEach((image) => {
        this.addImageControl(image);
      });
    }
  }
}
