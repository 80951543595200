import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { AuthorizationModule } from './modules/authorization/authorization.module';
import { GraphQLModule } from './core/graphql/graphql.module';
import { AuthModule } from './core/auth/auth.module';
import { NbThemeModule } from '@nebular/theme';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [AppComponent],
  imports: [
    NbThemeModule.forRoot({ name: 'default' }),
    BrowserModule,
    BrowserAnimationsModule,
    DashboardModule,
    AuthorizationModule,
    GraphQLModule,
    AuthModule,
    AppRoutingModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
