import gql from 'graphql-tag';

export const getCoachQuery = gql`
  query getCoach($coachId: ID!) {
    getCoach(coachId: $coachId) {
      coachId
      name
      description
      avatar {
        coachImageId
        key
        location
      }
      logo {
        coachImageId
        key
        location
      }
      companyName
      companyDescription
      companyWebsite
      linkedin
      instagram
      phone
      email
    }
  }
`;
