import gql from 'graphql-tag';

export const loginMutation = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      userId
      userRole
      accessToken
      refreshToken
    }
  }
`;

export const logout = gql`
  mutation logout {
    logout
  }
`;

export const refreshMutation = gql`
  mutation refreshSession($refreshToken: String!) {
    refreshSession(refreshToken: $refreshToken) {
      userId
      userRole
      accessToken
      refreshToken
    }
  }
`;
