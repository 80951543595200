import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const getMeQuery = gql`
  query {
    getMe {
      role {
        title
      }
    }
  }
`;

@Injectable()
export class AuthGuard implements CanActivate {
  public constructor(private authService: NbAuthService, private router: Router, private apollo: Apollo) {}

  public unauthorized(): boolean {
    this.router.navigate(['auth/login']);
    return false;
  }

  public async canActivate(): Promise<boolean> {
    const authenticated = await this.authService.isAuthenticated().toPromise();

    if (!authenticated) {
      return this.unauthorized();
    }

    const { errors, data }: any = await this.apollo.query({ query: getMeQuery }).toPromise();

    if (errors) {
      const tokenValue = await this.authService.getToken().toPromise();

      await this.authService.refreshToken('email', tokenValue).toPromise();

      return true;
    }

    const role = data.getMe.role.title;

    if (role !== 'Admin' && role !== 'Partner') {
      return this.unauthorized();
    }

    return true;
  }
}
