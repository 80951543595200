<div>
  <div class="input-group mr-2">
    <input
      [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'"
      class="form-control"
      (blur)="inputBlur()"
      [ngModel]="timeString"
      (change)="onInputChange($event)"
      disabled
    />

    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary"
        placement="auto"
        [ngbPopover]="timeContent"
        [disabled]="disabled"
        type="button"
      >
        <fa-icon [icon]="['far', 'calendar']"></fa-icon>
      </button>
    </div>
  </div>

  <ng-template #timeContent>
    <div>
      <div class="mt-auto">
        <ngb-timepicker
          name="timepicker"
          [ngModel]="time"
          (ngModelChange)="onTimeChange($event)"
          [seconds]="seconds"
          [hourStep]="hourStep"
          [minuteStep]="minuteStep"
          [secondStep]="1"
        ></ngb-timepicker>
      </div>
    </div>
  </ng-template>
</div>
