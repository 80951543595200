<div class="form-group" [formGroup]="form">
  <p class="label">{{ label }}:</p>
  <button nbButton class="leshack-upload-label" status="basic" (click)="upload.click()">
    <nb-icon icon="upload"></nb-icon>
  </button>
  <input #upload type="file" class="leshack-file-input" nbInput fullWidth id="file" (change)="onFileSelected($event)" />
  <div *ngIf="control.controls.length" class="alert" [formArrayName]="formControlName">
    <div class="leshack-file-field" *ngFor="let imageControl of control.controls; let itemIndex = index">
      {{ imageControl.value.key }}
      <nb-icon class="leshack-pointer" icon="close-outline" (click)="onFileRemove(itemIndex)"></nb-icon>
    </div>
  </div>
</div>
