<div class="form-group" [formGroup]="form">
  <div [formArrayName]="formControlName">
    <h6>{{ label }}:</h6>
    <div *ngFor="let item of control.controls; let itemIndex = index">
      <div class="form-group">
        <label [for]="'item' + itemIndex" class="label">Item {{ itemIndex + 1 }}:</label>
        <div class="leshack-removable-input">
          <input type="text" nbInput fullWidth [id]="'item' + itemIndex" [formControlName]="itemIndex" />
          <button nbButton status="danger" (click)="removeItem(itemIndex)">
            <nb-icon icon="close-outline"></nb-icon>
          </button>
        </div>
        <div
          *ngIf="getItem(itemIndex).invalid && (getItem(itemIndex).dirty || getItem(itemIndex).touched)"
          class="alert alert-danger"
        >
          <div *ngIf="getItem(itemIndex).errors.required">
            <nb-icon icon="alert-circle-outline"></nb-icon>
            Value is required.
          </div>
          <div *ngIf="getItem(itemIndex).errors.minlength">
            <nb-icon icon="alert-circle-outline"></nb-icon>
            Should be at least {{ minLength }} characters long.
          </div>
          <div *ngIf="getItem(itemIndex).errors.pattern">
            <nb-icon icon="alert-circle-outline"></nb-icon>
            Has wrong format.
          </div>
        </div>
      </div>
    </div>
    <button class="leshack-add-to-list-button" nbButton status="info" (click)="addItem()">
      <nb-icon icon="plus-outline"></nb-icon>
    </button>
  </div>
</div>
