<div class="form-group">
  <label for="number" class="label">{{ label }}:</label>
  <input
    [textMask]="{ mask: numberMask }"
    type="text"
    nbInput
    fullWidth
    id="number"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    (blur)="onTouch()"
  />
  <div *ngIf="control.invalid && (control.dirty || control.touched)" class="alert alert-danger">
    <div *ngIf="control.errors.required">
      <nb-icon icon="alert-circle-outline"></nb-icon>
      Is required.
    </div>
    <div *ngIf="control.errors.pattern">
      <nb-icon icon="alert-circle-outline"></nb-icon>
      Should be a number.
    </div>
  </div>
</div>
