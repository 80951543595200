import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '@core/guards/auth-guard.service';
import {
  CalendarModule,
  CoachModule,
  ConferenceModule,
  ExtraServiceModule,
  InvoiceModule,
  MeetingRoomModule,
  OrderModule,
  OrderRequestModule,
  PromoCodeModule,
  RoomModule,
  SeminaryRoomModule,
  UserModule,
  WellnessModule,
} from './modules';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      /*
        Dashboard
       */
      {
        path: 'calendar',
        loadChildren: (): Promise<CalendarModule> => import('./modules').then((m) => m.CalendarModule),
      },
      /*
        Extra services
       */
      {
        path: 'extra-services',
        loadChildren: (): Promise<ExtraServiceModule> => import('./modules').then((m) => m.ExtraServiceModule),
      },
      /*
        Coaches
       */
      {
        path: 'coaches',
        loadChildren: (): Promise<CoachModule> => import('./modules').then((m) => m.CoachModule),
      },
      /*
        Meeting rooms
      */
      {
        path: 'meeting-rooms',
        loadChildren: (): Promise<MeetingRoomModule> => import('./modules').then((m) => m.MeetingRoomModule),
      },

      /*
        Seminaries
     */
      {
        path: 'seminary-rooms',
        loadChildren: (): Promise<SeminaryRoomModule> => import('./modules').then((m) => m.SeminaryRoomModule),
      },

      /*
       Conferences
      */
      {
        path: 'conferences',
        loadChildren: (): Promise<ConferenceModule> => import('./modules').then((m) => m.ConferenceModule),
      },

      /*
        Wellness
      */
      {
        path: 'wellness-lessons',
        loadChildren: (): Promise<WellnessModule> => import('./modules').then((m) => m.WellnessModule),
      },
      /*
        Order requests
       */
      {
        path: 'requests',
        loadChildren: (): Promise<OrderRequestModule> => import('./modules').then((m) => m.OrderRequestModule),
      },
      /*
        Orders
       */
      {
        path: 'orders',
        loadChildren: (): Promise<OrderModule> => import('./modules').then((m) => m.OrderModule),
      },
      /*
        Invoices
       */
      {
        path: 'invoices',
        loadChildren: (): Promise<InvoiceModule> => import('./modules').then((m) => m.InvoiceModule),
      },
      /*
        Rooms
       */
      {
        path: 'rooms',
        loadChildren: (): Promise<RoomModule> => import('./modules').then((m) => m.RoomModule),
      },
      /*
        Promo codes
       */
      {
        path: 'promo-codes',
        loadChildren: (): Promise<PromoCodeModule> => import('./modules').then((m) => m.PromoCodeModule),
      },
      /*
        Users
       */
      {
        path: 'users',
        loadChildren: (): Promise<UserModule> => import('./modules').then((m) => m.UserModule),
      },
      /*
        Redirect
       */
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/calendar',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
