import { Injectable } from '@angular/core';

@Injectable()
export class ImageUploadService {
  private _pendingImageKeys = new Set<string>();

  public get pendingImageKeys(): string[] {
    return [...this._pendingImageKeys];
  }

  public addPendingKey(key: string): void {
    this._pendingImageKeys.add(key);
  }

  public removePendingKey(key: string): void {
    this._pendingImageKeys.delete(key);
  }

  public clearPendingKeys(): void {
    this._pendingImageKeys.clear();
  }
}
