import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ApolloLink, Observable } from 'apollo-link';
import { NbAuthService } from '@nebular/auth';
import { onError } from 'apollo-link-error';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpLink } from 'apollo-angular-link-http';

@Injectable()
export class GraphqlApolloLinkBuilder {
  public constructor(
    private readonly authService: NbAuthService,
    private readonly router: Router,
    private readonly httpLink: HttpLink,
  ) {}

  public withAuthApolloLink(): ApolloLink {
    return this.authorizationLink().concat(this.logoutLink().concat(this.refreshLink().concat(this.baseUriLink())));
  }

  private authorizationLink(): ApolloLink {
    return new ApolloLink((operation, forward) => {
      this.authService.getToken().subscribe((token) => {
        if (token) {
          operation.setContext({
            headers: new HttpHeaders().set('Authorization', `${(token.getValue() as any).accessToken}`),
          });
        }
      });

      return forward(operation);
    });
  }

  private logoutLink(): ApolloLink {
    return onError(({ graphQLErrors }) => {
      const error = graphQLErrors?.[0];

      if (error && error.extensions.code === 'UNAUTHENTICATED') {
        this.router.navigate(['/auth']).then(() => {
          localStorage.removeItem('auth_app_token');
        });
      }
    });
  }

  private refreshLink(): ApolloLink {
    return onError(({ graphQLErrors, operation, forward }) => {
      const error = graphQLErrors?.[0];

      if (error && error.extensions.code === 'UNAUTHENTICATED' && operation.operationName !== 'refreshSession') {
        return new Observable((observer) => {
          this.authService
            .getToken()
            .toPromise()
            .then((token) => {
              return this.authService.refreshToken('email', token).toPromise();
            })
            .then((result) => {
              const newToken = result.getToken();

              if (newToken) {
                operation.setContext({
                  headers: new HttpHeaders().set('Authorization', `${(newToken.getValue() as any).accessToken}`),
                });
              }
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              forward(operation).subscribe(subscriber);
            })
            .catch((error) => {
              observer.error(error);
            });
        });
      }
    });
  }

  private baseUriLink(): ApolloLink {
    return this.httpLink.create({ uri: environment.uri });
  }
}
