import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'app-number-form-control',
  templateUrl: './number-form-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFormControlComponent),
      multi: true,
    },
  ],
})
export class NumberFormControlComponent implements OnInit, ControlValueAccessor {
  @Input() public formControlName: string;

  @Input() public label: string;
  @Input() public pattern = /^\d+(\.\d{0,2})?$/;
  @Input() public isRequired = false;
  @Input() public allowDecimal = true;

  public numberMask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: this.allowDecimal,
  });

  public control: AbstractControl;
  public value: string;
  public onChange = (_: any): void => {};
  public onTouch = (): void => {};

  public constructor(private controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    this.numberMask = createNumberMask({
      prefix: '',
      includeThousandsSeparator: false,
      allowDecimal: this.allowDecimal,
    });

    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName);

        this.control.setValidators(this.defineValidators());
      } else {
        console.warn('Missing formControlName from form group component');
      }
    } else {
      console.warn('Missing form group container');
    }
  }

  public defineValidators(): ValidatorFn[] {
    const validators = [];

    if (this.isRequired) {
      validators.push(Validators.required);
    }

    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }

    return validators;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }
}
