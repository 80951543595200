import { NgModule } from '@angular/core';
import { NbGraphqlPasswordAuthStrategy } from './strategies/graphql-password-strategy';
import { AuthGuard } from '../guards/auth-guard.service';
import { NbAuthModule } from '@nebular/auth';

@NgModule({
  imports: [
    NbAuthModule.forRoot({
      strategies: [
        NbGraphqlPasswordAuthStrategy.setup({
          name: 'email',
        }),
      ],
    }),
  ],
  providers: [NbGraphqlPasswordAuthStrategy, AuthGuard],
  exports: [NbAuthModule],
})
export class AuthModule {}
