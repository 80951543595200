import { NgModule } from '@angular/core';
import {
  ApproveDialogComponent,
  DeleteDialogComponent,
  RejectDialogComponent,
  NameDialogComponent,
  PaginationComponent,
  DateTimePickerComponent,
  ImageUploaderComponent,
  TimePickerComponent,
  TextFormControlComponent,
  NumberFormControlComponent,
  SelectFormControlComponent,
  TextItemsFormControlComponent,
  ImageUploadFormControlComponent,
  UploadFileFormControlComponent,
} from './components';
import { ProviderModule } from '../provider/provider.module';
import { FileUploadService, ImageUploadService } from './services';

@NgModule({
  imports: [ProviderModule],
  providers: [FileUploadService, ImageUploadService],
  declarations: [
    ApproveDialogComponent,
    DeleteDialogComponent,
    RejectDialogComponent,
    NameDialogComponent,
    PaginationComponent,
    DateTimePickerComponent,
    ImageUploaderComponent,
    TimePickerComponent,
    TextFormControlComponent,
    SelectFormControlComponent,
    TextItemsFormControlComponent,
    ImageUploadFormControlComponent,
    UploadFileFormControlComponent,
    NumberFormControlComponent,
  ],
  exports: [
    ApproveDialogComponent,
    DeleteDialogComponent,
    RejectDialogComponent,
    NameDialogComponent,
    PaginationComponent,
    DateTimePickerComponent,
    ImageUploaderComponent,
    TimePickerComponent,
    TextFormControlComponent,
    NumberFormControlComponent,
    SelectFormControlComponent,
    TextItemsFormControlComponent,
    ImageUploadFormControlComponent,
    UploadFileFormControlComponent,
  ],
})
export class SharedModule {}
