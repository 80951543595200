<nb-card>
  <nb-card-header>Confirm</nb-card-header>
  <nb-card-body>
    <p>
      This is a destructive operation!<br />
      Do you want to proceed?
    </p>
  </nb-card-body>
  <nb-card-footer class="leshack-delete-dialog-footer">
    <button nbButton status="info" (click)="onCancel()">Cancel</button>
    <button nbButton status="danger" (click)="onApprove()">DELETE</button>
  </nb-card-footer>
</nb-card>
