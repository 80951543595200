import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { validateForm } from '../../../utils';

@Component({
  selector: 'app-text-items-form-control',
  templateUrl: './text-items-form-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextItemsFormControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextItemsFormControlComponent),
      multi: true,
    },
  ],
})
export class TextItemsFormControlComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() public formControlName: string;

  @Input() public label: string;
  @Input() public pattern = /^[^ ][\w\W ]*[^ ]/;
  @Input() public isRequired = false;
  @Input() public maxLength: number | undefined;
  @Input() public minLength: number | undefined;

  public form: FormGroup;
  public control: FormArray;

  public constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.defineForm();
  }

  private defineForm(): void {
    this.control = this.formBuilder.array([]);

    this.form = this.formBuilder.group({
      [this.formControlName]: this.control,
    });
  }

  public addItem(value = ''): void {
    this.control.push(this.formBuilder.control(value, this.defineValidators()));
  }

  public getItem(index: number): AbstractControl {
    return this.control.at(index);
  }

  public removeItem(index: number): void {
    this.control.removeAt(index);
  }

  private defineValidators(): ValidatorFn[] {
    const validators = [];

    if (this.isRequired) {
      validators.push(Validators.required);
    }

    if (this.minLength) {
      validators.push(Validators.minLength(this.minLength));
    }

    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }

    return validators;
  }

  public registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.control.statusChanges.subscribe(fn);
  }

  public writeValue(values: string[]): void {
    if (values && values.length) {
      values.forEach((value) => {
        this.addItem(value);
      });
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    validateForm(this.form);

    if (this.form.invalid) {
      const error = {};

      control.setErrors(error);

      return error;
    }
  }
}
