import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { noop } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { NgbPopoverConfig, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

import { TimeModel } from './model';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ],
})
export class TimePickerComponent implements ControlValueAccessor, OnInit {
  @Input()
  public timeString: string;
  @Input()
  public inputDatetimeFormat = 'HH:mm';

  @Input()
  public hourStep = 1;
  @Input()
  public minuteStep = 1;

  @Input()
  public seconds = false;
  @Input()
  public disabled = false;

  public time: TimeModel = new TimeModel();
  public ngControl: NgControl;

  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  public constructor(private config: NgbPopoverConfig, private inj: Injector) {
    config.autoClose = 'outside';
    config.placement = 'auto';
  }

  public ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
  }

  public writeValue(newModel: string): void {
    if (newModel) {
      this.time = Object.assign(this.time, this.time.fromLocalString(newModel));
      this.timeString = newModel;
      this.setDateStringModel();
    } else {
      this.time = new TimeModel();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onInputChange($event: any): void {
    const value = $event.target.value;
    const dt = this.time.fromLocalString(value);

    if (dt) {
      this.time = dt;
      this.setDateStringModel();
    } else if (value.trim() === '') {
      this.time = new TimeModel();
      this.timeString = '';
      this.onChange(this.timeString);
    } else {
      this.onChange(value);
    }
  }

  public onTimeChange(event: NgbTimeStruct): void {
    this.time.hour = event?.hour;
    this.time.minute = event?.minute;
    this.time.second = event?.second;

    this.setDateStringModel();
  }

  public setDateStringModel(): void {
    this.timeString = this.time.toString();
    this.onChange(this.timeString);
    this.onTouched();
  }

  public inputBlur(): void {
    this.onTouched();
  }
}
