import { NgModule } from '@angular/core';
import { ProviderModule } from '../../core/provider/provider.module';
import { NbCustomLoginComponent } from './components/login/login.component';
import { AuthorizationRouterModule } from './authorization-router.module';

@NgModule({
  declarations: [NbCustomLoginComponent],
  imports: [ProviderModule, AuthorizationRouterModule],
})
export class AuthorizationModule {}
