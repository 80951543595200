import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-text-form-control',
  templateUrl: './text-form-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFormControlComponent),
      multi: true,
    },
  ],
})
export class TextFormControlComponent implements OnInit, ControlValueAccessor {
  @Input() public formControlName: string;

  @Input() public label: string;
  @Input() public pattern = /^[^ ][\w\W ]*[^ ]/;
  @Input() public isRequired = false;
  @Input() public maxLength: number | undefined;
  @Input() public minLength: number | undefined;

  public control: AbstractControl;
  public value: string;
  public onChange = (_: any): void => {};
  public onTouch = (): void => {};

  public constructor(private controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName);

        this.control.setValidators(this.defineValidators());
      } else {
        console.warn('Missing formControlName from form group component');
      }
    } else {
      console.warn('Missing form group container');
    }
  }

  public defineValidators(): ValidatorFn[] {
    const validators = [];

    if (this.isRequired) {
      validators.push(Validators.required);
    }

    if (this.maxLength) {
      validators.push(Validators.maxLength(this.maxLength));
    }

    if (this.minLength) {
      validators.push(Validators.minLength(this.minLength));
    }

    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }

    return validators;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(value: string | null): void {
    this.value = value || '';
  }
}
