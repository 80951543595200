import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { ViewStateService } from '../../../services';
import { Guid } from 'guid-typescript';
import { PartialObserver } from 'rxjs';
import unorm from 'unorm';
import { FileControlData } from '../../../interfaces';

@Component({
  selector: 'app-upload-file-form-control',
  templateUrl: './upload-file-form-control.component.html',
  styleUrls: ['./upload-file-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadFileFormControlComponent),
      multi: true,
    },
  ],
})
export class UploadFileFormControlComponent implements OnInit, ControlValueAccessor {
  @Input() public formControlName: string;
  @Input() public label: string;
  @Input() public typePattern: RegExp;

  @Output() public fileRemoved = new EventEmitter<FileControlData>();

  public form: FormGroup;
  public control: FormArray;

  public constructor(
    private toastrService: NbToastrService,
    private formBuilder: FormBuilder,
    private viewStateService: ViewStateService,
  ) {}

  public ngOnInit(): void {
    this.viewStateService.setLoading(true);

    this.defineForm();

    this.viewStateService.setLoading(false);
  }

  private defineForm(): void {
    this.control = this.formBuilder.array([]);

    this.form = this.formBuilder.group({
      [this.formControlName]: this.control,
    });
  }

  public onFileSelected($event: Event): void {
    this.viewStateService.setLoading(true);

    if (this.control.controls.length) {
      this.viewStateService.setLoading(false);

      return;
    }

    const element = $event.target as HTMLInputElement;

    const file = element.files[0];

    element.value = '';

    if (!file) {
      this.viewStateService.setLoading(false);

      this.toastrService.warning('File upload failed', 'Warning', { icon: 'alert-triangle-outline' });

      return;
    }

    if (this.typePattern && !this.typePattern.test(file.type)) {
      this.viewStateService.setLoading(false);

      this.toastrService.warning('File has wrong format', 'Warning', { icon: 'alert-triangle-outline' });

      return;
    }

    const key = `${Guid.create()}-${unorm.nfc(file.name)}`;

    this.addFileControl({
      file,
      key,
    });

    this.viewStateService.setLoading(false);
  }

  public onFileRemove(index: number): void {
    this.viewStateService.setLoading(true);

    const fileData = this.control.controls[index].value as FileControlData;

    if (fileData.fileId) {
      this.fileRemoved.emit(fileData);
    }

    this.removeFileControl(index);

    this.viewStateService.setLoading(false);
  }

  private addFileControl(file: FileControlData): void {
    const control = this.formBuilder.control({ value: file, disabled: true });

    this.control.push(control);
  }

  private removeFileControl(index: number): void {
    this.control.removeAt(index);
  }

  public registerOnChange(fn: PartialObserver<unknown>): void {
    this.control.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: PartialObserver<unknown>): void {
    this.control.statusChanges.subscribe(fn);
  }

  public writeValue(values: FileControlData[] | null): void {
    if (values === null) {
      this.control.controls = [];
    }

    if (values && values.length) {
      values.forEach((image) => {
        this.addFileControl(image);
      });
    }
  }
}
