<div class="form-group">
  <label for="text" class="label">{{ label }}:</label>
  <input
    type="text"
    nbInput
    fullWidth
    id="text"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    (blur)="onTouch()"
  />
  <div *ngIf="control.invalid && (control.dirty || control.touched)" class="alert alert-danger">
    <div *ngIf="control.errors.required">
      <nb-icon icon="alert-circle-outline"></nb-icon>
      Is required.
    </div>
    <div *ngIf="control.errors.maxlength">
      <nb-icon icon="alert-circle-outline"></nb-icon>
      Must be not more than {{ maxLength }} characters long.
    </div>
    <div *ngIf="control.errors.minlength">
      <nb-icon icon="alert-circle-outline"></nb-icon>
      Must be at least {{ minLength }} characters long.
    </div>
    <div *ngIf="control.errors.pattern">
      <nb-icon icon="alert-circle-outline"></nb-icon>
      Has wrong format.
    </div>
  </div>
</div>
