import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

export interface NgbDateTimeStruct extends NgbDateStruct, NgbTimeStruct {}

export class DateTimeModel implements NgbDateTimeStruct {
  private readonly defaultFormat = 'YYYY-MM-DD HH:mm';

  public year: number;
  public month: number;
  public day: number;
  public hour = 0;
  public minute = 0;
  public second = 0;
  public timeZoneOffset = 0;

  public constructor(init?: Partial<DateTimeModel>) {
    Object.assign(this, init);
  }

  public fromLocalString(dateString: string, format?: string): DateTimeModel {
    const date = moment(dateString, format || this.defaultFormat).toDate();
    const isValidDate = !isNaN(date.valueOf());

    if (!dateString || !isValidDate) {
      return null;
    }

    return new DateTimeModel({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
      timeZoneOffset: date.getTimezoneOffset(),
    });
  }

  private static isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  }

  public toTimeString(): string {
    const pad = (num): string => {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

    const hour = this.hour.toString().padStart(2, '0');
    const minute = this.minute.toString().padStart(2, '0');

    return `${pad(hour)}:${pad(minute)}`;
  }

  public toString(): string {
    const pad = (num): string => {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

    if (
      DateTimeModel.isInteger(this.year) &&
      DateTimeModel.isInteger(this.month) &&
      DateTimeModel.isInteger(this.day)
    ) {
      const year = this.year.toString().padStart(2, '0');
      const month = this.month.toString().padStart(2, '0');
      const day = this.day.toString().padStart(2, '0');

      if (!this.hour) {
        this.hour = 0;
      }

      if (!this.minute) {
        this.minute = 0;
      }

      if (!this.second) {
        this.second = 0;
      }

      if (!this.timeZoneOffset) {
        this.timeZoneOffset = 0;
      }

      const hour = this.hour.toString().padStart(2, '0');
      const minute = this.minute.toString().padStart(2, '0');
      const second = this.second.toString().padStart(2, '0');

      const tzo = -this.timeZoneOffset;
      const dif = tzo >= 0 ? '+' : '-';

      return `${pad(year)}-${pad(month)}-${pad(day)}T${pad(hour)}:${pad(minute)}:${pad(second)}${dif}${pad(
        tzo / 60,
      )}:${pad(tzo % 60)}`;
    }

    return null;
  }
}
