<div class="form-group" [formGroup]="form">
  <p class="label">{{ label }}:</p>
  <button nbButton class="leshack-upload-label" (click)="upload.click()">Upload</button>
  <input
    #upload
    type="file"
    class="leshack-file-input"
    nbInput
    fullWidth
    id="images"
    (change)="onFileSelected($event)"
  />
  <div *ngIf="control.controls.length" class="alert" [formArrayName]="formControlName">
    <div class="leshack-image-field" *ngFor="let imageControl of control.controls; let itemIndex = index">
      {{ imageControl.value.key }}
      <nb-icon class="leshack-pointer" icon="close-outline" (click)="removeImage(itemIndex)"></nb-icon>
    </div>
  </div>
</div>
