import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { getImageUploadDataQuery } from '../gql';
import { ApolloQueryResult } from 'apollo-client';
import { BucketData, BucketFileData, IUploadDataResponse, UploadFileToBucket } from '../interfaces';
import { concatMap, map } from 'rxjs/operators';

@Injectable()
export class FileUploadService {
  private bucket: S3;
  private bucketName: string;

  public constructor(private apollo: Apollo) {}

  public uploadFile(input: UploadFileToBucket): Observable<BucketFileData> {
    return this.getBucketData().pipe(
      concatMap(({ bucket, bucketName }) => {
        const { file, key } = input;

        return new Observable<BucketFileData>((observer) => {
          bucket.upload(
            {
              Bucket: bucketName,
              Key: key,
              Body: file,
              ContentType: file.type,
              ACL: 'public-read',
            },
            (err, data) => {
              if (err) {
                observer.error(err);
              }

              const { Key, Location } = data;

              observer.next({
                key: Key,
                location: Location,
              });

              observer.complete();
            },
          );
        });
      }),
    );
  }

  public removeFile(key: string): Observable<void> {
    return this.getBucketData().pipe(
      concatMap(({ bucket, bucketName }) => {
        return new Observable<void>((observer) => {
          bucket.deleteObject({ Bucket: bucketName, Key: key }, (err) => {
            if (err) {
              observer.error(err);
            }

            observer.next();
            observer.complete();
          });
        });
      }),
    );
  }

  private getBucketData(): Observable<BucketData> {
    if (this.bucket && this.bucketName) {
      return of({
        bucket: this.bucket,
        bucketName: this.bucketName,
      });
    }

    return this.apollo
      .query({
        query: getImageUploadDataQuery,
      })
      .pipe(
        map(({ data: { getImageUploadData } }: ApolloQueryResult<IUploadDataResponse>) => {
          const { accessKey, bucketName, secretKey } = getImageUploadData;

          this.bucket = new S3({
            accessKeyId: accessKey,
            secretAccessKey: secretKey,
            region: 'eu-central-1',
          });

          this.bucketName = bucketName;

          return {
            bucketName,
            bucket: this.bucket,
          };
        }),
      );
  }
}
