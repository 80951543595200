<div>
  <div class="input-group mr-2">
    <input
      [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'"
      class="form-control"
      (blur)="inputBlur()"
      [ngModel]="dateString | date: inputDatetimeFormat"
      (change)="onInputChange($event)"
      disabled
    />
    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary"
        placement="right"
        [ngbPopover]="calendarContent"
        [disabled]="disabled"
        type="button"
      >
        <fa-icon [icon]="['far', 'calendar']"></fa-icon>
      </button>
    </div>
  </div>
  <ng-template #calendarContent>
    <div>
      <div *ngIf="!showTimePickerToggle">
        <ngb-datepicker name="datepicker" [ngModel]="dateTime" (ngModelChange)="onDateChange($event)"></ngb-datepicker>
        <button
          *ngIf="this.mode == 'datetime'"
          class="btn btn-block btn-outline-secondary"
          [disabled]="!dateTime?.day"
          [ngbPopover]="calendarContent"
          type="button"
          (click)="toggleDateTimeState($event)"
        >
          <fa-icon [icon]="['far', 'clock']"></fa-icon>
        </button>
      </div>
      <div *ngIf="showTimePickerToggle">
        <button
          *ngIf="this.mode == 'dateTime'"
          class="btn btn-block btn-outline-secondary"
          [ngbPopover]="calendarContent"
          type="button"
          (click)="toggleDateTimeState($event)"
        >
          <fa-icon [icon]="['far', 'calendar']"></fa-icon>
        </button>
        <div class="mt-auto">
          <ngb-timepicker
            name="timepicker"
            [ngModel]="dateTime"
            (ngModelChange)="onTimeChange($event)"
            [seconds]="seconds"
            [hourStep]="hourStep"
            [minuteStep]="minuteStep"
            [secondStep]="secondStep"
          ></ngb-timepicker>
        </div>
      </div>
    </div>
  </ng-template>
</div>
