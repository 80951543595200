import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Apollo } from 'apollo-angular';
import { noop } from 'rxjs';
import unorm from 'unorm';
import { Guid } from 'guid-typescript';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

import BucketMetadata from './bucket';
import { deleteCoachImageMutation } from 'src/app/modules/dashboard/modules/coach/gql';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true,
    },
  ],
})
export class ImageUploaderComponent implements ControlValueAccessor, OnInit {
  private onChange: (_: any) => void = noop;
  private onTouched: () => void = noop;
  public image: { key?: string; location?: string } = {};
  public control: NgControl;

  @Input()
  public disabled: boolean;
  @Input()
  public name: string;
  @Input()
  private meta: BucketMetadata;
  @Input()
  private setSpinner: (state: boolean) => void;
  @Input()
  private onImageRemoved: (image: { key?: string; location?: string }) => void;

  public constructor(private inj: Injector, private toastr: NbToastrService, private apollo: Apollo) {}

  public ngOnInit(): void {
    this.control = this.inj.get(NgControl);
  }

  public writeValue(obj: any): void {
    const image = obj as { key: string; location: string };
    if (obj !== null) {
      this.image = image;
    } else {
      this.image = { key: null, location: null };
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onImageSelected(event): void {
    const file = event.target.files[0];

    if (!file) {
      this.toastr.warning('File is undefined', 'Warning', { icon: 'alert-triangle-outline' });
      return;
    }

    const { type } = file;

    if (/\.(jpe?g|png)$/.test(type)) {
      this.toastr.warning('Not valid image type', 'Warning', { icon: 'alert-triangle-outline' });
      return;
    }

    this.setSpinner(true);

    if (this.image.key) {
      this.meta.bucket.deleteObject({ Bucket: this.meta.name, Key: this.image.key }, (err) => {
        if (err) {
          this.toastr.danger(err, 'Error', { icon: 'close-outline' });
        }
      });
    }
    const params = {
      Bucket: this.meta.name,
      Key: `${Guid.create()}-${unorm.nfc(file.name)}`,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read',
    };

    this.meta.bucket.upload(params, (err, data) => {
      if (err) {
        console.log(err, 'ERROR');
      }

      const { Key, Location } = data;
      this.image = {
        key: Key,
        location: Location,
      };

      this.onChange(this.image);

      event.target.value = '';
      this.setSpinner(false);
    });
  }

  public async removeImage(): Promise<void> {
    this.setSpinner(true);

    // FIXME:
    // if (this.onImageRemoved !== null) {
    //   await this.onImageRemoved(this.image);
    // }

    if (Object(this.image).hasOwnProperty('coachImageId')) {
      this.deleteImage((this.image as any).coachImageId);
    }

    await this.meta.bucket.deleteObject(
      {
        Bucket: this.meta.name,
        Key: this.image.key,
      },
      (err) => {
        if (err) {
          console.log(err);
        } else {
          this.image = {};
          this.onChange(null);
        }

        this.setSpinner(false);
      },
    );
  }

  private deleteImage(coachImageId): void {
    this.apollo
      .mutate({
        mutation: deleteCoachImageMutation,
        variables: { coachImageId },
      })
      .subscribe(
        () => {
          this.toastr.success('Image deleted', 'Success', { icon: 'checkmark-outline' });
        },
        (error) => {
          this.toastr.danger(error, 'Error', { icon: 'close-outline' });
        },
      );
  }
}
