<div class="form-group">
  <label for="select" class="label">{{ label }}:</label>
  <nb-select
    fullWidth
    placeholder="Form control"
    id="select"
    [ngModel]="value"
    (selectedChange)="onChange($event)"
    (blur)="onTouch()"
  >
    <nb-option *ngFor="let option of options" [value]="option.value">{{ option.displayText }}</nb-option>
  </nb-select>
  <div *ngIf="control.invalid && (control.dirty || control.touched)" class="alert alert-danger">
    <div *ngIf="control.errors.required">
      <nb-icon icon="alert-circle-outline"></nb-icon>
      Value is required.
    </div>
  </div>
</div>
