<div [className]="'pagination'">
  <button
    class="leshack-table-action-button"
    nbButton
    status="info"
    (click)="prevPage()"
    [disabled]="offset === 0 || !offset || count <= limit"
  >
    <nb-icon icon="arrow-ios-back-outline"></nb-icon>
    Prev
  </button>

  <button
    class="leshack-table-action-button"
    nbButton
    status="info"
    (click)="nextPage()"
    [disabled]="count - offset <= limit || count <= limit"
  >
    Next
    <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
  </button>
</div>
