import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-name-dialog-component',
  templateUrl: './name-dialog.component.html',
  styleUrls: ['./name-dialog.component.scss'],
})
export class NameDialogComponent {
  public name: string;

  public constructor(private dialogRef: NbDialogRef<NameDialogComponent>) {}

  public onApprove(): void {
    this.dialogRef.close(this.name);
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }
}
