import { NgModule } from '@angular/core';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { MenuComponent } from './components';
import { ProviderModule } from '../../core/provider/provider.module';
import { SharedModule } from '../../core/shared/shared.module';

@NgModule({
  declarations: [DashboardComponent, MenuComponent],
  imports: [DashboardRoutingModule, ProviderModule, SharedModule],
})
export class DashboardModule {}
