import { Guid } from 'guid-typescript';
import unorm from 'unorm';

export function prepareFileToUpload($event): void {
  const file = $event.target.files[0];
  if (!file) {
    this.toastr.warning('File is undefined', 'Warning', { icon: 'alert-triangle-outline' });
    return;
  }
  const { type } = file;

  if (/\.(jpe?g|png)$/.test(type)) {
    this.toastr.warning('File is undefined', 'Warning', { icon: 'alert-triangle-outline' });
    return;
  }

  this.spinner = true;
  const params = {
    Bucket: this.bucketName,
    Key: `${Guid.create()}-${unorm.nfc(file.name)}`,
    Body: file,
    ContentType: file.type,
    ACL: 'public-read',
  };
  this.bucket.upload(params, (err, data) => {
    if (err) {
      this.toastr.danger(err, 'Error', { icon: 'close-outline' });
    }
    const { Key, Location } = data;
    this.imageArray = [
      ...this.imageArray,
      {
        key: Key,
        location: Location,
      },
    ];
    $event.target.value = '';
    this.spinner = false;
  });
}
