import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export class TimeModel implements NgbTimeStruct {
  public hour = 0;
  public minute = 0;
  public second = 0;

  public constructor(init?: Partial<TimeModel>) {
    Object.assign(this, init);
  }

  public fromLocalString(timeString: string): TimeModel {
    const date = new Date(`2020-01-01T${timeString}+00:00`);
    date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
    const isValidDate = !isNaN(date.valueOf());

    if (!timeString || !isValidDate) {
      return null;
    }

    const result = new TimeModel({
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    });
    return result;
  }

  private static isInteger(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  }

  public toString(): string {
    const pad = (num): string => {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

    const hour = this.hour.toString().padStart(2, '0');
    const minute = this.minute.toString().padStart(2, '0');

    return `${pad(hour)}:${pad(minute)}`;
  }
}
